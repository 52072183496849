import { POLICY_OPTIONS } from '../../../../constants';

type UpdateHandlers = {
  updateGlLimit: (value: number) => void;
  updateBppDeductible: (value: number) => void;
  updatePlLimit: (value: number) => void;
  updatePlDeductible: (value: number) => void;
  updateBppLimit: (event: any) => void;
  updateBuildingLimit: (event: any) => void;
};

type SummaryValues = {
  buildingLimit?: number;
  bppLimit?: number;
  bppDeductible?: number;
  glLimit?: number;
  propertyDamageLiabilityDeductible?: number;
  plLimit?: number | null;
  plDeductible?: number | null;
  bppLimitError?: { type: string; value: number } | null;
  buildingLimitError?: { type: string; value: number } | null;
  incomeLimit?: number;
};

const getGlSummaries = (
  { updateGlLimit }: UpdateHandlers,
  { glLimit, propertyDamageLiabilityDeductible }: SummaryValues
) => [
  {
    option: POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT,
    value: glLimit,
    updateHandler: updateGlLimit,
  },
  {
    option: POLICY_OPTIONS.PROPERTY_DAMAGE_DEDUCTIBLE,
    value: propertyDamageLiabilityDeductible,
    inputType: 'text',
    isReadOnly: true,
  },
];

const getBopSummaries = (updateHandlers: UpdateHandlers, summaryValues: SummaryValues) => [
  ...getGlSummaries(updateHandlers, summaryValues),
  {
    option: POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT,
    value: summaryValues.bppLimit,
    inputValue: summaryValues.bppLimitError ? summaryValues.bppLimitError.value : summaryValues.bppLimit,
    updateHandler: updateHandlers.updateBppLimit,
    inputType: 'text',
    hasError: summaryValues.bppLimitError,
  },
  {
    option: POLICY_OPTIONS.BUILDING_LIMIT,
    value: summaryValues.buildingLimit,
    inputValue: summaryValues.buildingLimitError ? summaryValues.buildingLimitError.value : summaryValues.buildingLimit,
    updateHandler: updateHandlers.updateBuildingLimit,
    inputType: 'text',
    hasError: summaryValues.buildingLimitError,
  },
  {
    option: POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE,
    value: summaryValues.bppDeductible,
    updateHandler: updateHandlers.updateBppDeductible,
  },
  {
    option: POLICY_OPTIONS.WIND_HAIL_DEDUCTIBLE,
    value: '5% subject to $2,500 min',
    isReadOnly: true,
  },
  {
    option: POLICY_OPTIONS.BUSINESS_INCOME_LIMIT,
    value: summaryValues.incomeLimit,
    isReadOnly: true,
  },
];

const getPlSummaries = (
  { updatePlLimit, updatePlDeductible }: UpdateHandlers,
  { plLimit, plDeductible }: SummaryValues
) => [
  {
    option: POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT,
    value: plLimit,
    updateHandler: updatePlLimit,
  },
  {
    option: POLICY_OPTIONS.DEDUCTIBLE,
    value: plDeductible,
    updateHandler: updatePlDeductible,
  },
];

export const summariesAttributesFactory = (
  updateHandlers: UpdateHandlers,
  summaryValues: SummaryValues
): { [key: string]: any } => ({
  BOP: getBopSummaries(updateHandlers, summaryValues),
  GL: getGlSummaries(updateHandlers, summaryValues),
  PL: getPlSummaries(updateHandlers, summaryValues),
});
