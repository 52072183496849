import get from 'lodash/get';

const base_application_properties = [
  'legalBusinessName',
  'industryId',
  'contactFirstName',
  'contactLastName',
  'contactEmail',
  'mailingAddressStreet',
  'mailingAddressCity',
  'mailingAddressState',
  'mailingAddressZip',
  'locations[0].zip',
];

const pl_specific_properties = [
  'professionalLiability.yearsOfPriorActs',
  'professionalLiability.deductibleAmount',
  'professionalLiability.occurrenceLimit',
  'professionalLiability.yearsOfProfessionalExperience',
  'professionalLiability.certificationsRequired',
  'professionalLiability.certificationsMaintained',
  'professionalLiability.grossRevenue',
];

const gl_specific_properties = ['numEmployees', 'glLimit'];

const bop_specific_properties = [...gl_specific_properties, 'bppDeductible', 'locations[0].bppLimit'];

const isApplicationBindable = (application) => {
  if (!application.applicationTypes || application.applicationTypes.length === 0) {
    return false;
  }
  const includePL = application.applicationTypes.includes('PL') ? pl_specific_properties : [];
  const includeGL = application.applicationTypes.includes('GL') ? gl_specific_properties : [];
  const includeBOP = application.applicationTypes.includes('BOP') ? bop_specific_properties : [];
  const final = [...includePL, ...includeGL, ...includeBOP, ...base_application_properties];
  const missingProperties = final.filter((property) => !(typeof get(application, property) !== 'undefined'));

  return missingProperties.length === 0;
};

export default isApplicationBindable;
