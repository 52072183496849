import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  setLocations,
  setNumEmployees,
  setLegalBusinessName,
  setDBA,
  setDBAIsValid,
  setBusinessStartDate,
} from '../actions/application';
import ToolTipIcon from '../Components/Shared/ToolTipIcon';
import SectionDivider from '../Components/Shared/SectionDivider';
import MultiButton from '../Components/Shared/MultiButton';
import PreviousLosses from '../Components/BusinessInfo/PreviousLosses';
import { STATE_ZIP_VERIFICATION_WARNING, stateAbbrevOptions } from '../constants';
import {
  selectNumEmployees,
  selectFailFastZip,
  selectIndustryState,
  selectLegalBusinessName,
  selectDBA,
  selectFirstLocation,
  selectLocationState,
  selectBusinessStartDate,
} from '../selectors/application';
import {
  FormSection,
  LabelLarge,
  Input,
  Container,
  ToolTipHeader,
  ContainerRow,
  LocationQuestionContainer,
  InputWithToolTip,
  NumberInput,
  PatternInput,
} from '../elements';
import Dropdown from '../elements/Dropdown';
import GhostButton from '../Components/Shared/GhostButton';
import handleFormSubmit from '../actions/handleFormSubmit';
import { DesktopAndTablet, Desktop, MobileAndTablet } from '../helpers/responsive';
import { NumberFormatValues } from 'react-number-format';
import styled from 'styled-components';
import toolTipContent from '../helpers/toolTipContent';
import Checkbox from '../elements/Checkbox';
import { verifyAddressStateAndZip } from '../api';
import { throttle } from 'lodash';
import { setZipStateVerified } from '../actions/businessInfo';
import { format } from 'date-fns';
import { ReactComponent as AlertIcon } from '../icons/alert.svg';
import { isBusinessStartDateValid } from '../helpers/isBusinessStartDateValid';

interface BusinessInfoProps {
  legalBusinessName?: string;
  dba?: string;
  businessStartDate?: string;
  numEmployees?: number;
  businessZip: string;
  businessState?: string;
  industryId?: number;
  location?: ApplicationLocation;
  setLocations: (location: Array<object>) => void;
  setNumEmployees: (num: number) => void;
  setLegalBusinessName: (name: string) => void;
  setDBA: (name: string) => void;
  setDBAIsValid: (isValid: boolean) => void;
  setBusinessStartDate: (date: string) => void;
  setZipStateVerified: (isVerified: boolean) => void;
}

const BusinessInfo = ({
  legalBusinessName,
  dba,
  businessStartDate,
  numEmployees,
  businessZip,
  businessState,
  industryId,
  location,
  setLocations,
  setNumEmployees,
  setLegalBusinessName,
  setDBA,
  setDBAIsValid,
  setBusinessStartDate,
  setZipStateVerified,
}: BusinessInfoProps) => {
  useEffect(() => {
    document.title = `Coterie - Business Basics`;
    window.scrollTo(0, 0);
  }, []);

  const [hasDBA, setHasDBA] = useState(Boolean(dba));
  const [showZipStateError, setShowZipStateError] = useState(false);
  const [showBusinessStartDateError, setShowBusinessStartDateError] = useState(false);
  const [businessStartDateErrorMessage, setBusinessStartDateErrorMessage] = useState<string | undefined>();

  const dateInputRef = useRef<HTMLInputElement>(null);

  const throttledVerifyAddressStateAndZip = useCallback(
    throttle((businessState, businessZip) => {
      // only call zip state verification endpoint if zip code is entered fully
      if (!businessState || !businessZip || businessZip.length < 5) return;

      verifyAddressStateAndZip(businessState, businessZip)
        .then((res) => {
          setShowZipStateError(false);
          setZipStateVerified(true);
        })
        .catch((error) => {
          const hasMismatchingState = error?.response?.data?.includes(STATE_ZIP_VERIFICATION_WARNING.stateErrorText);
          const hasMismatchingZip = error?.response?.data?.includes(STATE_ZIP_VERIFICATION_WARNING.zipErrorText);

          if (hasMismatchingState || hasMismatchingZip) {
            setShowZipStateError(true);
            setZipStateVerified(false);
          }
        });
    }, 1500),
    []
  );

  useEffect(() => {
    throttledVerifyAddressStateAndZip(businessState, businessZip);
  }, [businessState, businessZip, throttledVerifyAddressStateAndZip]);

  const handleDbaCheck = () => {
    setHasDBA((p) => {
      if (p) {
        setDBA('');
        setDBAIsValid(true);
      } else {
        setDBAIsValid(false);
      }
      p = !hasDBA;
      return p;
    });
  };

  const validDba = hasDBA ? Boolean(dba) : true;
  const validZip = Boolean(businessZip?.length === 5 || businessZip?.length === 10);

  const updateBusinessZip = (e: { target: HTMLInputElement }) => {
    const input = e.target.value;
    const hasNonNumberValues = input.match(/[^0-9]/g);
    if (input.length <= 5 && !hasNonNumberValues) {
      setLocations([{ ...location, zip: input }]);
    }
  };

  const updateBusinessState = (selection: { value: string; label: string }) => {
    setLocations([{ ...location, state: selection.value }]);
  };

  const adjustNumEmployees = (numEmployees: number) => {
    setNumEmployees(numEmployees);
  };

  const updateLegalBusinessName = (e: { target: HTMLInputElement }) => {
    setLegalBusinessName(e.target.value);
  };

  const updateDBA = (e: { target: HTMLInputElement }) => {
    setDBA(e.target.value);
    setDBAIsValid(true);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleFormSubmit();
  };

  const handleBusinessStartDateChange = (value: string) => {
    // Only set the business start date if it's a valid date format (MM/YYYY)
    if (value?.match(/^(0[1-9]|1[0-2])\/\d{4}$/)) {
      const [month, year] = value.split('/');
      const date = new Date(parseInt(year), parseInt(month) - 1, 1);
      setShowBusinessStartDateError(!isBusinessStartDateValid(date).isValid);
      setBusinessStartDateErrorMessage(isBusinessStartDateValid(date).message);
      setBusinessStartDate(date?.toISOString());
    }

    if (!value) {
      setShowBusinessStartDateError(false);
      setBusinessStartDateErrorMessage(undefined);
      setBusinessStartDate('');
    }
  };

  const handleBusinessStartDateBlur = (value: string) => {
    if (dateInputRef.current && businessStartDate) {
      dateInputRef.current.value = format(new Date(businessStartDate), 'MM/yyyy');
    }
  };

  useEffect(() => {
    if (businessStartDate) {
      // Show error if business start date is not valid on initial load
      setShowBusinessStartDateError(!isBusinessStartDateValid(new Date(businessStartDate)).isValid);
      setBusinessStartDateErrorMessage(isBusinessStartDateValid(new Date(businessStartDate)).message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container data-cy="business-info">
      <form onSubmit={handleSubmit}>
        <ToolTipHeader>
          <LabelLarge display="inline">Legal Business Name</LabelLarge>
          <DesktopAndTablet>
            <ToolTipIcon content={toolTipContent['LEGAL_BUSINESS_NAME']} toolTipName="Legal_Business_Name" />
          </DesktopAndTablet>
        </ToolTipHeader>
        <InputWithToolTip
          toolTipContentKey="LEGAL_BUSINESS_NAME"
          toolTipName="Legal_Business_Name"
          labelElementId="legalBusinessNameInput"
          marginSmall={true}
        >
          <Input
            value={legalBusinessName}
            onChange={updateLegalBusinessName}
            data-cy="legal-business-name"
            aria-label="Enter the legal name of your business"
            placeholder="Enter your legal business name"
            id="legalBusinessNameInput"
          />
        </InputWithToolTip>

        <DbaContainer>
          <Checkbox
            onChange={handleDbaCheck}
            selected={hasDBA}
            label="My business uses a separate Doing Business As or operating name"
            id="dba-checkbox"
          />

          {hasDBA && (
            <>
              <ToolTipHeader>
                <LabelLarge display="inline">Doing Business As</LabelLarge>
                <DesktopAndTablet>
                  <ToolTipIcon content={toolTipContent['DBA']} toolTipName="DBA" />
                </DesktopAndTablet>
              </ToolTipHeader>
              <InputWithToolTip toolTipContentKey="DBA" toolTipName="DBA" labelElementId="dbaInput">
                <Input
                  value={dba}
                  onChange={updateDBA}
                  data-cy="dba"
                  aria-label="Enter the DBA of your business"
                  placeholder="Enter your DBA"
                  id="dbaInput"
                />
              </InputWithToolTip>
            </>
          )}
        </DbaContainer>
        <DateInputContainer>
          <LabelLarge>Business Start Date</LabelLarge>
          <DateInput
            getInputRef={dateInputRef}
            value={businessStartDate ? format(new Date(businessStartDate), 'MM/yyyy') : undefined}
            onChange={({ target: { value } }) => handleBusinessStartDateChange(value)}
            onBlur={({ target: { value } }) => handleBusinessStartDateBlur(value)}
            name="businessStartDate"
            placeholder="MM/YYYY"
            format="##/####"
            type="text"
            isAllowed={(values) => {
              const { formattedValue } = values;
              if (formattedValue) {
                const [month] = formattedValue.split('/');
                if (parseInt(month) > 12) return false;
              }
              return true;
            }}
            data-cy="businessStartDate"
            customWidth="full"
            aria-label="business start date"
            marginBottom="smaller"
          />
          {showBusinessStartDateError && (
            <ErrorContainer>
              <ErrorIcon />
              <ErrorText>{businessStartDateErrorMessage}</ErrorText>
            </ErrorContainer>
          )}
        </DateInputContainer>
        <FormSection available={industryId !== undefined}>
          <ToolTipHeader>
            <LabelLarge display="inline">How many employees does your business have?</LabelLarge>
            <DesktopAndTablet>
              <ToolTipIcon content={toolTipContent['NUMBER_EMPLOYEES']} toolTipName="Number_Employees" />
            </DesktopAndTablet>
          </ToolTipHeader>
          <Desktop>
            <MultiButton
              available={true}
              value={numEmployees}
              updateValue={adjustNumEmployees}
              directLabel="Enter the total number of employees"
              options={[
                {
                  title: '10 or fewer',
                  values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                },
                {
                  title: '11-20',
                  values: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                },
                {
                  title: 'More than 20',
                  customInput: '>30', // shouldn't be boolean, should be value to use at end of values array

                  values: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                  placeholder: 'Number of employees',
                },
              ]}
            />
          </Desktop>
          <MobileAndTablet>
            <LocationQuestionContainer>
              <InputWithToolTip
                toolTipContentKey="NUMBER_EMPLOYEES"
                toolTipName="Number_Employees"
                labelElementId="numEmployeesInput"
              >
                <NumberInput
                  value={numEmployees}
                  onValueChange={(values: NumberFormatValues) => {
                    if (values.floatValue) adjustNumEmployees(values.floatValue);
                  }}
                  customWidth="half"
                  aria-label="Number of employees"
                  placeholder="eg., 10"
                  id="numEmployeesInput"
                />
              </InputWithToolTip>
            </LocationQuestionContainer>
          </MobileAndTablet>
        </FormSection>
        <FormSection available={Boolean(legalBusinessName && businessStartDate && numEmployees)}>
          <ContainerRow>
            <LocationQuestionContainer>
              <LabelLarge>Business State</LabelLarge>
              <Dropdown
                onChange={updateBusinessState}
                options={stateAbbrevOptions}
                value={businessState || ''}
                name="state"
                ariaLabel="State"
                isDisabled={!Boolean(legalBusinessName && businessStartDate && numEmployees)}
                hasError={showZipStateError}
              />
            </LocationQuestionContainer>
            <LocationQuestionContainer>
              <LabelLarge>Business Zip Code</LabelLarge>
              <Input
                data-cy="zip-input"
                value={businessZip}
                onChange={updateBusinessZip}
                width="half"
                disabled={!Boolean(legalBusinessName && businessStartDate && numEmployees)}
                aria-label="Zip Code"
                required
                aria-required
                hasError={showZipStateError}
                marginBottom="none"
              />
            </LocationQuestionContainer>
          </ContainerRow>
          {showZipStateError && <ZipStateWarning>{STATE_ZIP_VERIFICATION_WARNING.text}</ZipStateWarning>}
        </FormSection>
        <SectionDivider />
        <PreviousLosses
          disabled={!(industryId && numEmployees && legalBusinessName && businessStartDate && validZip && validDba)}
        />
        <GhostButton />
      </form>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  numEmployees: selectNumEmployees(state) || 0,
  businessZip: selectFailFastZip(state) || '',
  industryId: selectIndustryState(state),
  legalBusinessName: selectLegalBusinessName(state) || '',
  dba: selectDBA(state) || '',
  businessStartDate: selectBusinessStartDate(state),
  location: selectFirstLocation(state),
  businessState: selectLocationState(state),
});

const mapDispatchToProps = {
  setLocations,
  setNumEmployees,
  setLegalBusinessName,
  setDBA,
  setDBAIsValid,
  setBusinessStartDate,
  setZipStateVerified,
};

const DbaContainer = styled.div`
  margin-bottom: 32px;
`;

const ZipStateWarning = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.formElements.input.errorTextColor};
  margin-top: 8px;
`;

const DateInput = styled(PatternInput)`
  margin: 8px 0;
`;
const DateInputContainer = styled.div`
  margin-bottom: 32px;
`;

const ErrorIcon = styled(AlertIcon)`
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: block;

  g {
    fill: ${(props) => props.theme.colors.alert.zero};
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 8px 0;
  width: 100%;
`;

const ErrorText = styled.p`
  color: ${(props) => props.theme.colors.alert.zero};
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.09px;
  margin: 0;
`;

export default connect(mapStateToProps, mapDispatchToProps)(BusinessInfo);
