import { isBefore, addDays } from 'date-fns';

type Params = {
  isValid: boolean;
  message?: string;
};
export const isBusinessStartDateValid = (date: Date): Params => {
  let message;
  const isValidYear = new Date(date).getFullYear() >= 1800;
  const isValidDate = isBefore(date, addDays(new Date(), 90));

  if (!isValidYear) message = 'The business start date must be the year 1800 or later.';
  if (!isValidDate) message = 'Business start date must be in the past or up to a maximum of 90 days in the future.';

  return {
    isValid: isValidYear && isValidDate,
    message,
  };
};
