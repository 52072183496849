import {
  CLEAR_APPLICATION,
  ADD_ADDITIONAL_INSURED,
  REMOVE_ADDITIONAL_INSURED,
  CLEAR_ADDITIONAL_INSUREDS,
  IS_LOADING,
  FATAL_ERROR,
  CLEAR_ALL_DATA,
  ADD_LOCATION,
  DELETE_LOCATION,
  UPDATE_LOCATION,
  SET_AVAILABLE_POLICY_TYPES,
  SET_EXISTING_MAILING_ADDRESS,
  ADD_POTENTIAL_ADDRESS,
  SET_HIRED_AND_NON_OWNED_AUTO,
  ADD_PREVIOUS_LOSS,
  EDIT_PREVIOUS_LOSS,
  REMOVE_PREVIOUS_LOSS,
  CLEAR_PREVIOUS_LOSSES,
  SET_PARTNER_INFO,
  SET_APPLICATION_FROM_SERVER,
  SET_INDUSTRY_NAME,
  UPDATE_APPLICATION_FROM_SERVER,
  SET_ACKNOWLEDGEMENTS,
  UPDATE_PROFESSIONAL_LIABILITY,
  UPDATE_START_DATE,
  UPDATE_END_DATE,
  DID_VIEW_ADDITIONAL_INSUREDS,
  DID_VIEW_SUMMARY_PAGE,
  DID_VIEW_GL_PAGE,
  DID_VIEW_ADD_ONS_PAGE,
  SET_PARTNER_FROM_APPLICATION,
  PARTNER_INTRO_VIEWED,
  RESET_PREVIOUS_LOSSES,
  EXISTING_APPLICATION_LOADED,
  ADD_EXISTING_MAILING_TO_LOCATIONS,
  SET_INDUSTRY_ID,
  SET_AK_HASH,
  CLEAR_INDUSTRY_STATE,
  UPDATE_APPLICATION_LOCALLY,
  SET_ANNUAL_PAYROLL,
  SET_GROSS_ANNUAL_SALES,
  SET_BPP_DEDUCTIBLE,
  SET_BPP_LIMIT,
  SET_GL_LIMIT,
  SET_TWO_FOUR_LIMITS_AVAILABLE,
  SET_CONTACT_FIRST_NAME,
  SET_CONTACT_LAST_NAME,
  SET_CONTACT_EMAIL,
  SET_CONTACT_PHONE,
  SET_LOCATIONS,
  SET_NUM_EMPLOYEES,
  SET_LEGAL_BUSINESS_NAME,
  SET_DBA,
  SET_BUSINESS_START_DATE,
  SET_APPLICATION_TYPES,
  SET_ENDORSEMENT_PACKAGE,
  USER_SELECTED_HAS_ADDITIONAL_INSUREDS,
  USER_COMPLETED_CHECKOUT_FORM,
  USER_SUBMITTED_CHECKOUT_FORM,
  RESET_USER_COMPLETED_CHECKOUT_FORM,
  RESET_USER_SUBMITTED_CHECKOUT_FORM,
  SET_LIQUOR_LIABILITY_COVERAGE,
  SET_RESTAURANT_COVERAGE,
  SET_EQUIPMENT_BREAKDOWN_COVERAGE,
  INVALIDATED_ADDON_ENDORSEMENT,
  DBA_IS_VALID,
  SET_CONTACT_EMAIL_IS_VALID,
  SET_LOSS_CONTROL,
  SET_LIMIT_DAMAGE_PREM_RENTED,
  SET_CONTRACTOR_TOOLS_COVERAGE,
  SET_BLANKET_ADDITIONAL_INSUREDS,
  REMOVE_BLANKET_ADDITIONAL_INSUREDS,
  TOGGLE_BLANKET_AI_ITEM,
  SET_CYBER_COVERAGE,
  SET_WORKPLACE_VIOLENCE_COVERAGE,
} from '../actions/application';

import locationsCheck from '../helpers/locationsCheck';
import { validStartDate } from '../helpers/dates';
import { BLANKET_AI_PAYLOAD, QUOTE_COVERAGE_DEFAULTS } from '../constants';
import { BlanketAIItem } from '../types/enums';

export const initialState = {
  endorsements: {
    additionalInsureds: [],
  },
  previousLosses: null,
  locations: [],
  metadata: '',
  contactEmailIsValid: true,
};

export function application(state: Application = initialState, action: Action) {
  switch (action.type) {
    case SET_INDUSTRY_ID:
      return Object.assign({}, state, {
        industryId: action.data,
        endorsements: {
          additionalInsureds: state.endorsements?.additionalInsureds,
        },
      });
    case SET_AK_HASH:
      return Object.assign({}, state, {
        akHash: action.data,
        endorsements: {
          additionalInsureds: state.endorsements?.additionalInsureds,
        },
      });
    case CLEAR_INDUSTRY_STATE:
      return Object.assign({}, state, {
        industryId: undefined,
        industryName: '',
        akHash: '',
        endorsements: {
          additionalInsureds: state.endorsements?.additionalInsureds,
        },
      });
    case SET_APPLICATION_FROM_SERVER:
      const application = { ...action.data, businessAgeInMonths: undefined };
      if (application.policyStartDate) {
        const validDate = validStartDate(application.policyStartDate);
        return Object.assign({}, initialState, {
          ...application,
          policyStartDate: validDate,
          policyEndDate: null,
        });
      } else if (application.industryId && !application.akHash) {
        delete application.industryId;

        return Object.assign({}, initialState, { ...application });
      } else {
        return Object.assign({}, initialState, { ...application });
      }
    case UPDATE_APPLICATION_LOCALLY:
      return Object.assign({}, state, { ...action.data, businessAgeInMonths: undefined });
    case UPDATE_APPLICATION_FROM_SERVER:
      return Object.assign({}, {}, { ...action.data, businessAgeInMonths: undefined });
    case SET_ENDORSEMENT_PACKAGE:
      return Object.assign({}, state, {
        endorsements: {
          ...state.endorsements,
          endorsementPackage: action.data,
        },
      });
    case SET_LIQUOR_LIABILITY_COVERAGE:
      return Object.assign({}, state, {
        endorsements: {
          ...state.endorsements,
          liquorLiabilityEndorsement: action.data,
        },
      });
    case SET_LIMIT_DAMAGE_PREM_RENTED:
      const getDamagePremRentedLimit = () => {
        const isEmpty = action.data && Object.keys(action.data).length === 0;
        const isNumber = typeof action.data === 'number';

        // unchecking
        if (!action.data) {
          return null;
          // on add on check action gets called with {}, when that is that case default limit to 50k
        } else if (!isNumber && isEmpty) {
          return 50_000;
          // on summary view, this action is only getting called with the value so we just return the value (action.data)
        } else if (isNumber) {
          return action.data;
        } else {
          // when overriding the limit on the add on view, the shape from action.data looks like { limitDamagePremRented: x }
          return action.data.limitDamagePremRented;
        }
      };

      return Object.assign({}, state, {
        limitDamagePremRented: getDamagePremRentedLimit(),
      });
    case SET_CONTRACTOR_TOOLS_COVERAGE:
      const getSelectedContractorsToolsLimitValue = () => {
        const isEmpty = action.data && Object.keys(action.data).length === 0;
        if (isEmpty) {
          return { selectedContractorsToolsLimit: 5_000 };
        } else if (action.data?.selectedContractorsToolsLimit) {
          return action.data;
        } else {
          return null;
        }
      };

      return Object.assign({}, state, {
        endorsements: {
          ...state.endorsements,
          contractorsInstallationAndTools: getSelectedContractorsToolsLimitValue(),
        },
      });
    case SET_RESTAURANT_COVERAGE:
      return Object.assign({}, state, {
        endorsements: {
          ...state.endorsements,
          restaurantCoverage: action.data,
        },
      });
    case SET_EQUIPMENT_BREAKDOWN_COVERAGE:
      return Object.assign({}, state, {
        endorsements: {
          ...state.endorsements,
          equipmentBreakdownEndorsement: action.data,
        },
      });
    case SET_CYBER_COVERAGE:
      return Object.assign({}, state, {
        endorsements: {
          ...state.endorsements,
          cyberCoverage: action.data,
        },
      });
    case SET_WORKPLACE_VIOLENCE_COVERAGE:
      return Object.assign({}, state, {
        endorsements: {
          ...state.endorsements,
          workplaceViolenceCoverage: action.data,
        },
      });
    case SET_APPLICATION_TYPES:
      return Object.assign({}, state, { applicationTypes: action.data });
    case SET_LOCATIONS:
      return Object.assign({}, state, { locations: action.data });
    case SET_NUM_EMPLOYEES:
      return Object.assign({}, state, { numEmployees: action.data });
    case SET_LEGAL_BUSINESS_NAME:
      return Object.assign({}, state, { legalBusinessName: action.data });
    case SET_DBA:
      return Object.assign({}, state, { dba: action.data });
    case SET_BUSINESS_START_DATE:
      return Object.assign({}, state, { businessStartDate: action.data });
    case UPDATE_PROFESSIONAL_LIABILITY:
      return Object.assign({}, state, { professionalLiability: { ...state.professionalLiability, ...action.data } });
    case ADD_ADDITIONAL_INSURED:
      const previousAddInsureds = state?.endorsements?.additionalInsureds || [];
      return Object.assign({}, state, {
        endorsements: {
          ...state.endorsements,
          additionalInsureds: [...previousAddInsureds, { ...action.data }],
        },
      });
    case REMOVE_ADDITIONAL_INSURED:
      const updatedAdditionalInsureds = state.endorsements?.additionalInsureds.filter(
        (option: AdditionalInsured) => option.name !== action.data
      );
      return Object.assign({}, state, {
        endorsements: {
          ...state.endorsements,
          additionalInsureds: updatedAdditionalInsureds,
        },
      });
    case CLEAR_ADDITIONAL_INSUREDS:
      return Object.assign({}, state, {
        endorsements: {
          ...state.endorsements,
          additionalInsureds: [],
        },
      });

    case SET_BLANKET_ADDITIONAL_INSUREDS:
      return Object.assign({}, state, {
        endorsements: {
          ...state.endorsements,
          ...BLANKET_AI_PAYLOAD.primaryAndNonNonContributory,
          ...BLANKET_AI_PAYLOAD.waiverOfTransfer,
          ...BLANKET_AI_PAYLOAD.constructionContractRequirement,
          ...BLANKET_AI_PAYLOAD.managersOrLessorsPremises,
          ...BLANKET_AI_PAYLOAD.lessorOfLeasedEquipment,
        },
      });

    case REMOVE_BLANKET_ADDITIONAL_INSUREDS: {
      const {
        additionalInsuredsPrimaryAndNonContributory,
        additionalInsuredsWaiverOfTransfer,
        additionalInsuredsConstructionContractRequirementEndorsement,
        additionalInsuredsManagersOrLessorsPremisesEndorsement,
        additionalInsuredsLessorOfLeasedEquipmentEndorsement,
        ...restEndorsements
      } = state.endorsements || {};
      return Object.assign({}, state, {
        endorsements: restEndorsements,
      });
    }
    case TOGGLE_BLANKET_AI_ITEM: {
      const selectedBlanketAIPayload = BLANKET_AI_PAYLOAD[action.data as BlanketAIItem];
      const selectedBlanketAIKey = Object.keys(selectedBlanketAIPayload)[0];
      const selectedBlanketAIPayloadExists = state?.endorsements?.[selectedBlanketAIKey];

      if (selectedBlanketAIPayloadExists) {
        const newEndorsementState = { ...state.endorsements };
        delete newEndorsementState[selectedBlanketAIKey];
        return Object.assign({}, state, {
          ...state,
          endorsements: newEndorsementState,
        });
      } else {
        return Object.assign({}, state, {
          ...state,
          endorsements: {
            ...state.endorsements,
            ...selectedBlanketAIPayload,
          },
        });
      }
    }
    case SET_HIRED_AND_NON_OWNED_AUTO:
      const data =
        action.data === true
          ? {
              hiredCoverage: 'hiredAutoLiability',
              nonOwnedCoverage: 'nonOwnedAutoLiabilityWithoutDeliveryService',
            }
          : action.data || null;

      return Object.assign({}, state, {
        endorsements: {
          ...state.endorsements,
          hiredAutoAndNonOwnedAuto: data,
        },
      });
    case ADD_EXISTING_MAILING_TO_LOCATIONS:
      return Object.assign({}, state, {
        locations: [
          {
            ...state.locations?.[0],
            street: state.mailingAddressStreet,
            city: state.mailingAddressCity,
            state: state.mailingAddressState,
            zip: state.mailingAddressZip,
          },
        ],
      });

    case ADD_LOCATION:
      const bizLocations = state.locations && state.locations.length ? locationsCheck(state) : [];
      return Object.assign({}, state, {
        locations: [...bizLocations, { ...action.data, bppLimit: QUOTE_COVERAGE_DEFAULTS.BPP_LIMIT }],
      });
    case UPDATE_LOCATION:
      const locations = state.locations?.map((location) => {
        if (location.street === action.data.street) {
          return action.data;
        }
        return location;
      });
      return Object.assign({}, state, { locations });
    case DELETE_LOCATION:
      const newLocations = state.locations?.filter((location) => location.street !== action.data);
      return Object.assign({}, state, { locations: newLocations });
    case SET_EXISTING_MAILING_ADDRESS:
    case ADD_POTENTIAL_ADDRESS:
      return Object.assign({}, state, {
        mailingAddressStreet: action.data.street,
        mailingAddressCity: action.data.city,
        mailingAddressState: action.data.state,
        mailingAddressZip: action.data.zip,
      });
    case SET_PARTNER_INFO: {
      const existingMetadata = state.metadata?.length ? JSON.parse(state.metadata) : {};
      const newMetadata = Object.assign({}, existingMetadata, {
        ...action.data,
      });
      const newMetadataString = JSON.stringify(newMetadata);
      return Object.assign({}, state, { metadata: newMetadataString });
    }
    case ADD_PREVIOUS_LOSS:
      const existingLosses = Array.isArray(state.previousLosses) ? state.previousLosses : [];
      return Object.assign({}, state, {
        previousLosses: [...existingLosses, action.data],
      });
    case EDIT_PREVIOUS_LOSS:
      const previousLosses = state.previousLosses || [];
      previousLosses?.splice(action.data.index, 1, action.data.claim);
      return Object.assign({}, state, {
        previousLosses: [...previousLosses],
      });
    case REMOVE_PREVIOUS_LOSS:
      const filteredList = state.previousLosses?.filter((loss, index) => index !== action.data) || [];
      return Object.assign({}, state, { previousLosses: filteredList });
    case CLEAR_PREVIOUS_LOSSES:
      return Object.assign({}, state, { previousLosses: [] });
    case RESET_PREVIOUS_LOSSES:
      return Object.assign({}, state, { previousLosses: null });
    case UPDATE_START_DATE:
      return Object.assign({}, state, action.data);
    case UPDATE_END_DATE:
      return Object.assign({}, state, action.data);
    case SET_ANNUAL_PAYROLL:
      return Object.assign({}, state, { annualPayroll: action.data });
    case SET_GROSS_ANNUAL_SALES:
      return Object.assign({}, state, { grossAnnualSales: action.data });
    case SET_BPP_DEDUCTIBLE:
      return Object.assign({}, state, { bppDeductible: action.data });
    case SET_BPP_LIMIT:
      return Object.assign({}, state, {
        locations: [
          {
            ...state.locations?.[0],
            bppLimit: action.data,
          },
        ],
      });
    case SET_GL_LIMIT:
      return Object.assign({}, state, { glLimit: action.data });
    case SET_TWO_FOUR_LIMITS_AVAILABLE:
      return Object.assign({}, state, { twoFourLimitsAvailable: action.data });
    case SET_CONTACT_FIRST_NAME:
      return Object.assign({}, state, { contactFirstName: action.data });
    case SET_CONTACT_LAST_NAME:
      return Object.assign({}, state, { contactLastName: action.data });
    case SET_CONTACT_EMAIL:
      return Object.assign({}, state, { contactEmail: action.data });
    case SET_CONTACT_PHONE:
      return Object.assign({}, state, { contactPhone: action.data });
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
      return { ...initialState, metadata: state.metadata };
    case SET_CONTACT_EMAIL_IS_VALID:
      return { ...state, contactEmailIsValid: action.data };
    case SET_LOSS_CONTROL:
      return Object.assign({}, state, { lossControl: action.data });
    default:
      return state;
  }
}

const availablePolicyTypesTest = ['GL', 'BOP', 'PL'];

export function availablePolicyTypes(state = availablePolicyTypesTest, action: Action) {
  switch (action.type) {
    case SET_AVAILABLE_POLICY_TYPES:
      return action.data;
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
      return [];
    default:
      return state;
  }
}

export function potentialMailingAddresses(state = [], action: Action) {
  switch (action.type) {
    case ADD_LOCATION:
    case ADD_POTENTIAL_ADDRESS:
      // If the address is already in the list of potential addresses don't add it
      if (state.filter((location: Location) => location.street === action.data.street).length) {
        return state;
      } else {
        return [...state, action.data];
      }
    case DELETE_LOCATION:
      return state.filter((location: Location) => location.street !== action.data);
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
      return [];
    default:
      return state;
  }
}

export function fatalError(state = '', action: Action) {
  switch (action.type) {
    case FATAL_ERROR:
      return action.data;
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
      return '';
    default:
      return state;
  }
}

export function applicationIsLoading(state = false, action: Action) {
  switch (action.type) {
    case IS_LOADING:
      return action.data;
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
      return false;
    default:
      return state;
  }
}

const iaPartnerInitialState = {
  fromPartnerLink: false,
};

export function partner(state: PartnerInfo = iaPartnerInitialState, action: Action) {
  switch (action.type) {
    case SET_PARTNER_INFO:
      return Object.assign({}, state, { ...action.data });
    case CLEAR_APPLICATION:
      return state;
    case CLEAR_ALL_DATA:
      return { ...iaPartnerInitialState, agencyExternalId: state.agencyExternalId };
    default:
      return state;
  }
}

export function industryName(state = '', action: Action) {
  switch (action.type) {
    case SET_INDUSTRY_NAME:
      return action.data;
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
      return '';
    default:
      return state;
  }
}

export function acknowledgements(state = {}, action: Action) {
  switch (action.type) {
    case SET_ACKNOWLEDGEMENTS:
      return action.data;
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
      return {};
    default:
      return state;
  }
}

export function partnerFromApplication(state = { introViewed: false }, action: Action) {
  switch (action.type) {
    case SET_PARTNER_FROM_APPLICATION:
      return { ...state, ...action.data };
    case PARTNER_INTRO_VIEWED:
      return { ...state, introViewed: action.data };
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
      return { introViewed: false };
    default:
      return state;
  }
}

export function existingApplicationLoaded(state = null, action: Action) {
  switch (action.type) {
    case EXISTING_APPLICATION_LOADED:
      return action.data;
    case CLEAR_ALL_DATA:
    case CLEAR_APPLICATION:
      return null;
    default:
      return state;
  }
}

export const defaultUserHasState = {
  selectedHasAdditionalInsureds: false,
  completedCheckoutForm: false,
  submittedCheckoutForm: false,
  viewed: {
    gl_page: false,
    additional_insureds_page: false,
    add_ons_page: false,
    summary_page: false,
  },
  invalidatedAddOns: [],
};

export function userHas(state = defaultUserHasState, action: Action) {
  switch (action.type) {
    case USER_SELECTED_HAS_ADDITIONAL_INSUREDS:
      return { ...state, selectedHasAdditionalInsureds: action.data };
    case USER_COMPLETED_CHECKOUT_FORM:
      return { ...state, completedCheckoutForm: true };
    case USER_SUBMITTED_CHECKOUT_FORM:
      return { ...state, submittedCheckoutForm: true };
    case RESET_USER_COMPLETED_CHECKOUT_FORM:
      return { ...state, completedCheckoutForm: false };
    case RESET_USER_SUBMITTED_CHECKOUT_FORM:
      return { ...state, submittedCheckoutForm: false };
    case INVALIDATED_ADDON_ENDORSEMENT:
      const currentInvalidedAddons = state.invalidatedAddOns;
      const invalidatedAddOns = [...currentInvalidedAddons, action.data];
      return {
        ...state,
        invalidatedAddOns,
      };
    case DID_VIEW_ADDITIONAL_INSUREDS:
      return {
        ...state,
        viewed: {
          ...state.viewed,
          additional_insureds_page: true,
        },
      };
    case DID_VIEW_SUMMARY_PAGE:
      return {
        ...state,
        viewed: {
          ...state.viewed,
          summary_page: true,
        },
      };
    case DID_VIEW_GL_PAGE:
      return {
        ...state,
        viewed: {
          ...state.viewed,
          gl_page: true,
        },
      };
    case DID_VIEW_ADD_ONS_PAGE:
      return {
        ...state,
        viewed: {
          ...state.viewed,
          add_ons_page: true,
        },
      };
    case SET_APPLICATION_TYPES:
    case SET_AK_HASH:
      return {
        ...state,
        invalidatedAddOns: [],
        viewed: {
          ...state.viewed,
          add_ons_page: false,
        },
      };
    case CLEAR_ALL_DATA:
    case CLEAR_APPLICATION:
      return defaultUserHasState;
    default:
      return state;
  }
}

export function dbaIsValid(state = true, action: Action) {
  switch (action.type) {
    case DBA_IS_VALID:
      return action.data;
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
      return true;
    default:
      return state;
  }
}
