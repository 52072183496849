export const isRealEstateClass = (akHash: string): boolean => {
  const realEstateClasses = [
    'eb96d01419b227fd689a265c2ae6382e',
    '7815d7fa4b89f27e63f756ca13249a10',
    '16b9d82a04c81d254f4ee08b4f7571c0',
    '11dece4202bdb9a5737374dc6b2a175a',
    '87d6ec04194cf17bc8327d1cda2e2886',
    '440b2dde6ae3d148a32e0378df69f49d',
    '4d28d1616ffad5428ae4ba055912b1f9',
    'ea869c9749c998ab98fd13e584e8d206',
    'f6eb20b528ad45f8c280d580932a365b',
    '0e1a6ea25ee151b4e9172ae05bef9b95',
    'b9803c0fb57d98ba4aa130efb1f8bc68',
    'c7d370f8d7fdde80cf2fc6a86a67aef4',
    '00e9b24a4f191d43ea3d66da3aa3a469',
    'ca8ec1843dd6d9f7fbee54abd8efb700',
    'd55235f631bf5f0a040a466c7e7968b8',
    '413d4aca352d135f250fcc7ff7b96db5',
    '7a1d2f1009ee0519a1a3f9d47e74e636',
    '48566d7adb83f7878535923d14f905e4',
    '14bf3cd6fd67aa7245b7fb3d55bf78eb',
    '571360bac1ad8cf8e7c6c4ba0d7c1949',
    'c28eba43aa824d921d584306b001cd34',
    'd87d473f6b723d825c18025680b1a8fa',
    'ab9c09548f5374efb2f1915949688987',
  ];
  return realEstateClasses.includes(akHash);
};
