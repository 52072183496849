import { omit } from 'lodash';
import { useState, useEffect } from 'react';
import { anonymousRateCall } from '../../../api';
import { POLICY_ENUMS } from '../../../constants';
import { formatPriceToNumber } from '../../../helpers/formatNumber';
import { PolicyType } from '../../../types/enums';

export const addPLFieldsForQuote = (applicationTypes: PolicyType[]) => ({
  applicationTypes: [...applicationTypes, POLICY_ENUMS.PL],
  professionalLiability: {
    certificationsMaintained: true,
    certificationsRequired: true,
    deductibleAmount: 1500,
    grossRevenue: 0,
    occurrenceLimit: 500000,
    periodLoading: 0,
    yearsOfPriorActs: 1,
    yearsOfProfessionalExperience: 0,
  },
});

export const addGLFieldsForQuote = (applicationTypes: PolicyType[]) => ({
  applicationTypes: [...applicationTypes, POLICY_ENUMS.GL],
  glLimit: 1000000,
  propertyDamageLiabilityDeductible: 0,
  glAggregateLimit: 2000000,
  glAggregatePcoLimit: 2000000,
});

export const addBOPFieldsForQuote = (
  applicationTypes: PolicyType[],
  city: string,
  street: string,
  state: string,
  zip: string
) => ({
  applicationTypes: [...applicationTypes.filter((p) => p !== POLICY_ENUMS.GL), POLICY_ENUMS.BOP],
  bppDeductible: 2500,
  glLimit: 1000000,
  propertyDamageLiabilityDeductible: 0,
  locations: [
    {
      locationType: 'Home',
      isPrimaryLocation: true,
      bppLimit: 25000.0,
      buildingLimit: 0.0,
      hasSprinklers: false,
      city,
      street,
      state,
      zip,
    },
  ],
});

const useGetQuoteDifference = (
  currentQuoteAmount: string,
  application: Application,
  policy: string,
  city: string,
  street: string,
  state: string,
  zip: string,
  sourceData: SourceData,
  agencyExternalId: string
) => {
  const [difference, setDifference] = useState<null | number>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [quoteError, setError] = useState<boolean>(false);

  useEffect(() => {
    const addPolicySpecificFields = (applicationTypes: PolicyType[]) => {
      if (policy === POLICY_ENUMS.PL) {
        return addPLFieldsForQuote(applicationTypes);
      } else if (policy === POLICY_ENUMS.GL) {
        return addGLFieldsForQuote(applicationTypes);
      } else if (policy === POLICY_ENUMS.BOP) {
        return addBOPFieldsForQuote(applicationTypes, city, street, state, zip);
      }
    };
    if (!difference) {
      setLoading(true);
      const altApplication = omit(
        { ...application, ...addPolicySpecificFields(application.applicationTypes || []) },
        'applicationId'
      );
      anonymousRateCall({ ...altApplication, ...sourceData, agencyExternalId: agencyExternalId }).then((res) => {
        if (res.data.isSuccess) {
          setError(false);
          const addPolicyQuote = res.data.quote.monthlyPremium;
          const currentQuote = formatPriceToNumber(currentQuoteAmount);
          setDifference(Number((addPolicyQuote - currentQuote).toFixed(0)));
        } else {
          setError(true);
        }
        setLoading(false);
      });
    }
  }, [application, currentQuoteAmount, policy, difference, city, street, state, zip, sourceData, agencyExternalId]);

  return { difference, loading, quoteError };
};

export default useGetQuoteDifference;
