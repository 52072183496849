import PropTypes from 'prop-types';
import { BlanketAIItem } from './types/enums';

export const PAGES = {
  HOME: 'HOME',
  INDUSTRY: 'INDUSTRY',
  BUSINESS_INFORMATION: 'BUSINESS_INFORMATION',
  POLICIES: 'POLICIES',
  FINANCE_BASICS: 'FINANCE_BASICS',
  LOCATIONS: 'LOCATIONS',
  GENERAL_LIABILITY: 'GENERAL_LIABILITY',
  PROFESSIONAL_LIABILITY: 'PROFESSIONAL_LIABILITY',
  ADDITIONAL_INSUREDS: 'ADDITIONAL_INSUREDS',
  CONTACT_INFORMATION: 'CONTACT_INFORMATION',
  ADD_ONS: 'ADD_ONS',
  SUMMARY: 'SUMMARY',
  PAYMENT: 'PAYMENT',
  QUOTEHELP: 'QUOTEHELP',
  APPLICATION: 'APPLICATION',
  PARTNER: 'PARTNER',
  AGENCY: 'AGENCY',
  QUICKQUOTE: 'QUICKQUOTE',
  WHITE_LABEL_DEV: 'WHITE_LABEL_DEV',
  MDA_INBOUND: 'MDA_INBOUND',
};

export const pageOrder = [
  PAGES.INDUSTRY,
  PAGES.BUSINESS_INFORMATION,
  PAGES.POLICIES,
  PAGES.FINANCE_BASICS,
  PAGES.LOCATIONS,
  PAGES.GENERAL_LIABILITY,
  PAGES.PROFESSIONAL_LIABILITY,
  PAGES.ADDITIONAL_INSUREDS,
  PAGES.CONTACT_INFORMATION,
  PAGES.ADD_ONS,
  PAGES.SUMMARY,
  PAGES.PAYMENT,
];

export const POLICY_NAMES: { [key: string]: string } = {
  GL: 'General Liability Policy',
  BOP: "Business Owner's Policy",
  PL: 'Professional Liability Policy',
};

export const POLICY_ENUMS = {
  BOP: 'BOP',
  GL: 'GL',
  PL: 'PL',
};

export const PREFILLED_INTRO_TEXT = (name: string) =>
  `Through our partners at ${name}, we have the below information about you already. Your privacy is our top priority–your info is all encrypted, confidential, and secure.`;

export const PREFILLED_INTRO_NO_DATA_TEXT = (name: string) =>
  `We're so glad that you're interested in protecting yourself - and your business. ${name} has teamed up with Coterie to provide you with a quick, affordable, and accurate business insurance policy - in minutes. Select Continue below to get started!`;

export const PREMIER_PLATFORM_TEXT = (name: string) =>
  `As a thank you for being a valued customer of ${name} we would like to offer you a discount towards your policy.`;

export const QUICKBOOKS_PARTNER_IDS = {
  TEST: '7e96f74d-ecf3-4dae-92d7-ab0664a9111e',
  SANDBOX: '2a3be96c-cca8-4846-85dc-7eab52f1577e',
  PRODUCTION: '7e96f74d-ecf3-4dae-92d7-ab0664a9111e',
};

export const IES_PARTNER_IDS = {
  TEST: '8b0ae0cd-87ed-44ae-a4eb-c17b1ef2d0f7',
  SANDBOX: '42ebdc9f-4fdc-4a6a-b9d6-41f400e079e9',
  PRODUCTION: '42ebdc9f-4fdc-4a6a-b9d6-41f400e079e9',
};

export const COTERIE_PARTNER_ID = 'f6f8a0bc-ae66-4e92-87a8-ffcdd6e0afa5';

export const POLICY_DEFAULTS = {
  PL: {
    professionalLiability: {
      deductibleAmount: 1500,
      occurrenceLimit: 500000,
    },
  },
  GL_BOP: {
    propertyDamageLiabilityDeductible: 0,
    lossControl: 'OptIn',
  },
};

interface IDictionary<TValue> {
  [id: string]: TValue;
}

export const PL_LIMIT_OCC_AGG: IDictionary<number> = {
  25000: 50000,
  50000: 100000,
  100000: 250000,
  250000: 500000,
  500000: 1000000,
  750000: 2000000,
  1000000: 3000000,
};

export const ERRORS = {
  CAT_1: {
    NUMBER_OF_EMPLOYEES_OUTSIDE_BOUNDS: 'Number of Employees outside bounds',
    PREVIOUS_LOSSES_OUTSIDE_BOUNDS: 'Previous losses outside bounds',
    REVENUE_OUTSIDE_BOUNDS: 'Revenue is outside bounds',
    ZIP_CODE_OUTSIDE_BOUNDS: 'Zip Code is outside bounds',
    INVALID_INDUSTRY_ID: 'Invalid Industry',
    UNACTIONABLE_REASONABILITY_ERROR: 'Unactionable reasonability error',
    DECLINATION_ERROR: 'Unactionable declination error',
  },
};

export const POLICY_OPTIONS = {
  BUSINESS_PERSONAL_PROPERTY_LIMIT: 'BUSINESS_PERSONAL_PROPERTY_LIMIT',
  BUILDING_LIMIT: 'BUILDING_LIMIT',
  PROPERTY_CLAIMS_DEDUCTIBLE: 'PROPERTY_CLAIMS_DEDUCTIBLE',
  PROPERTY_CLAIMS_DEDUCTIBLE_FL: 'PROPERTY_CLAIMS_DEDUCTIBLE_FL',
  GENERAL_LIABILITY_LIMIT: 'GENERAL_LIABILITY_LIMIT',
  PROPERTY_DAMAGE_DEDUCTIBLE: 'PROPERTY_DAMAGE_DEDUCTIBLE',
  PROFESSIONAL_LIABILITY_LIMIT: 'PROFESSIONAL_LIABILITY_LIMIT',
  DEDUCTIBLE: 'DEDUCTIBLE',
  YEARS_OF_PRIOR_ACTS: 'YEARS_OF_PRIOR_ACTS',
  WIND_HAIL_DEDUCTIBLE: 'WIND_HAIL_DEDUCTIBLE',
  BUSINESS_INCOME_LIMIT: 'BUSINESS_INCOME_LIMIT',
};

export const PROPERTY_LABELS = {
  [POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT]: 'Business Personal Property Limit',
  [POLICY_OPTIONS.BUILDING_LIMIT]: 'Building Limit',
  [POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE]: 'Property Claims Deductible',
  [POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT]: 'General Liability Limit',
  [POLICY_OPTIONS.PROPERTY_DAMAGE_DEDUCTIBLE]: 'Property Damage Liability Deductible',
  [POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT]: 'Professional Liability Limit',
  [POLICY_OPTIONS.DEDUCTIBLE]: 'Deductible',
  [POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE_FL]: 'Property Deductible',
  [POLICY_OPTIONS.WIND_HAIL_DEDUCTIBLE]: 'Property Deductible',
  [POLICY_OPTIONS.BUSINESS_INCOME_LIMIT]: 'Business Income Limit',
};

export const PROPERTY_LABEL_SUBTITLES = {
  [POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE]: 'All Other Perils',
  [POLICY_OPTIONS.WIND_HAIL_DEDUCTIBLE]: 'Wind and Hail',
};

export const POLICY_ARIA_LABELS = {
  [POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT]: 'Business Personal Property Limit',
  [POLICY_OPTIONS.BUILDING_LIMIT]: 'Building Limit',
  [POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE]: 'Property Claims Deductible',
  [POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT]: 'General liability claim amount',
  [POLICY_OPTIONS.PROPERTY_DAMAGE_DEDUCTIBLE]: 'Property damage claim deductible amount',
  [POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT]: 'Professional liability claims coverage amount',
  [POLICY_OPTIONS.DEDUCTIBLE]: 'Professional liability claims deductible amount',
  [POLICY_OPTIONS.YEARS_OF_PRIOR_ACTS]: 'Years of prior acts coverage',
};

export const TOOL_TIP_CONTENT_KEY = {
  [POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT]: 'BPP_LIMIT',
  [POLICY_OPTIONS.BUILDING_LIMIT]: 'BUILDING_LIMIT',
  [POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE]: 'BIZ_PROP_DEDUCTIBLE',
  [POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT]: 'GL',
  [POLICY_OPTIONS.PROPERTY_DAMAGE_DEDUCTIBLE]: 'PD_DEDUCTIBLE',
  [POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT]: 'PL_LIMITS',
  [POLICY_OPTIONS.DEDUCTIBLE]: 'PL_DEDUCTIBLE',
  [POLICY_OPTIONS.WIND_HAIL_DEDUCTIBLE]: 'WIND_HAIL_DEDUCTIBLE',
};

export const VALUE_OPTIONS = {
  [POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT]: [25000, 75000, 150000, 250000, 500000],
  [POLICY_OPTIONS.BUILDING_LIMIT]: [10000, 150000, 250000, 500000, 750000, 1000000],
  [POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE]: [500, 1000, 2500, 5000, 7500, 10000],
  [POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE_FL]: [1000, 2500],
  [POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT]: [300000, 500000, 1000000, 2000000],
  [POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT]: [25000, 50000, 250000, 500000, 1000000],
  [POLICY_OPTIONS.DEDUCTIBLE]: [0, 250, 750, 1500, 2500, 5000, 7500, 20000, 50000],
  [POLICY_OPTIONS.YEARS_OF_PRIOR_ACTS]: [0, 1, 2, 3, 4, 5, 6],
};

const PROFESSIONAL_LIABILITY = PropTypes.shape({
  aggregateLimit: PropTypes.number,
  certificationsMaintained: PropTypes.bool,
  certificationRequired: PropTypes.bool,
  deductibleAmount: PropTypes.number,
  grossRevenue: PropTypes.number,
  occurrenceLimit: PropTypes.number,
  yearsOfPriorActs: PropTypes.number,
  yearsOfProfessionalExperience: PropTypes.number,
});

const LOCATIONS = PropTypes.arrayOf(
  PropTypes.shape({
    bppLimit: PropTypes.number,
    buildingLimit: PropTypes.number,
    city: PropTypes.string,
    hasSprinklers: PropTypes.bool,
    isPrimaryLocation: PropTypes.bool,
    locationType: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    zip: PropTypes.string,
  })
);

const ADDITIONAL_INSUREDS = PropTypes.arrayOf(
  PropTypes.shape({
    city: PropTypes.string,
    email: PropTypes.string,
    fullAddress: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    zip: PropTypes.string,
  })
);

const PREVIOUS_LOSSES = PropTypes.arrayOf(
  PropTypes.shape({
    amount: PropTypes.number,
    description: PropTypes.string,
  })
);

const HIRED_AUTO_AND_NON_OWNED_AUTO = PropTypes.shape({
  limit: PropTypes.number,
  shouldInclude: PropTypes.bool,
  hiredCoverage: PropTypes.string,
  nonOwnedCoverage: PropTypes.string,
});

const QUOTE = PropTypes.shape({
  applicationUrl: PropTypes.string,
  externalId: PropTypes.string,
  isEstimate: PropTypes.bool,
  monthlyPremium: PropTypes.number,
  premium: PropTypes.number,
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      applicationId: PropTypes.string,
      applicationUrl: PropTypes.string,
      isEstimate: PropTypes.bool,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number,
          description: PropTypes.string,
          lineItemType: PropTypes.string,
          premiumType: PropTypes.string,
        })
      ),
      policyType: PropTypes.string,
      premium: PropTypes.number,
      quoteId: PropTypes.string,
      ratingEngine: PropTypes.string,
      stateNoticeText: PropTypes.string,
    })
  ),
  stateNoticeText: PropTypes.string,
});

export const PROP_TYPES = {
  APPLICATION: PropTypes.shape({
    applicationId: PropTypes.string,
    applicationTypes: PropTypes.arrayOf(PropTypes.string),
    applicationUrl: PropTypes.string,
    bppDeductible: PropTypes.number,
    businessStartDate: PropTypes.string,
    legalBusinessName: PropTypes.string,
    dba: PropTypes.string,
    contactEmail: PropTypes.string,
    contactFirstName: PropTypes.string,
    contactLastName: PropTypes.string,
    contactPhone: PropTypes.string,
    endorsements: PropTypes.shape({
      additionalInsureds: ADDITIONAL_INSUREDS,
      hiredAutoAndNonOwnedAuto: HIRED_AUTO_AND_NON_OWNED_AUTO,
    }),
    glLimit: PropTypes.number,
    grossAnnualSales: PropTypes.number,
    industryid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locations: LOCATIONS,
    mailingAddressCity: PropTypes.string,
    mailingAddressState: PropTypes.string,
    mailingAddressStreet: PropTypes.string,
    mailingAddressZip: PropTypes.string,
    metadata: PropTypes.string,
    numEmployees: PropTypes.number,
    previousLosses: PREVIOUS_LOSSES,
    professionalLiability: PROFESSIONAL_LIABILITY,
    propertyDamageLiabilityDeductible: PropTypes.number,
    status: PropTypes.string,
  }),
  PREVIOUS_LOSSES,
  ADDITIONAL_INSUREDS,
  LOCATIONS,
  PROFESSIONAL_LIABILITY,
  HIRED_AUTO_AND_NON_OWNED_AUTO,
  QUOTE,
};

export const gross_annual_sales_options = [
  {
    title: '$500,000 or less',
    values: [50000, 100000, 150000, 200000, 350000, 500000],
  },
  {
    title: 'more than $500,000',
    customInput: '>$3M',
    values: [750000, 1000000, 1250000, 1500000, 1750000, 2000000, 2500000, 3000000],
  },
];

export const annual_payroll_options = [
  {
    title: '$500,000 or less',
    startValue: 500000,
    values: [50000, 100000, 150000, 200000, 350000, 500000],
  },
  {
    title: 'more than $500,000',

    customInput: '>$3M',
    values: [750000, 1000000, 1250000, 1500000, 1750000, 2000000, 2500000, 3000000],
  },
];

export const ENVIRONMENT_URL = {
  PRODUCTION: `quote.${process.env.REACT_APP_DOMAIN}`,
  MDA: `app.${process.env.REACT_APP_DOMAIN}`,
  SANDBOX: `quote-sandbox.${process.env.REACT_APP_DOMAIN}`,
  TEST: `quote-test.${process.env.REACT_APP_DOMAIN}`,
  LOCALHOST: 'localhost',
  DEV_1: 'dev1-quoteflow-east-app-service.azurewebsites.net',
  DEV_2: 'dev2-quoteflow-east-app-service.azurewebsites.net',
  DEV_3: 'dev3-quoteflow-east-app-service.azurewebsites.net',
  DEV_1_STAGING: 'dev1-quoteflow-east-app-service-staging.azurewebsites.net',
  DEV_2_STAGING: 'dev2-quoteflow-east-app-service-staging.azurewebsites.net',
  DEV_3_STAGING: 'dev3-quoteflow-east-app-service-staging.azurewebsites.net',
};

export const EndorsementOptions = {
  SILVER: 'Silver',
  GOLD: 'Gold',
  PLATINUM: 'Platinum',
};

export const stateAbbrevOptions = [
  { value: '', label: '' },
  { value: 'AL', label: 'AL' },
  { value: 'AK', label: 'AK' },
  { value: 'AZ', label: 'AZ' },
  { value: 'AR', label: 'AR' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DE', label: 'DE' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'IA', label: 'IA' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'ME', label: 'ME' },
  { value: 'MD', label: 'MD' },
  { value: 'MA', label: 'MA' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MS', label: 'MS' },
  { value: 'MO', label: 'MO' },
  { value: 'MT', label: 'MT' },
  { value: 'NE', label: 'NE' },
  { value: 'NV', label: 'NV' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NY', label: 'NY' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VT', label: 'VT' },
  { value: 'VA', label: 'VA' },
  { value: 'WA', label: 'WA' },
  { value: 'WV', label: 'WV' },
  { value: 'WI', label: 'WI' },
  { value: 'WY', label: 'WY' },
];

export const INPUT_ERROR_MSG: { [key: string]: string } = {
  BPP_OVER_LIMIT: 'Coverage must be less than $500,000',
  BPP_UNDER_LIMIT: 'Coverage must be at least $5,000',
  BUILDING_OVER_LIMIT: 'Coverage cannot be greater than $1,000,000',
  BUILDING_UNDER_LIMIT: 'Coverage cannot be less than $25,000',
  INVALID_PHONE_NUMBER: 'Please enter a valid phone number',
  INVALID_EMAIL: 'Please enter a valid email address',
};

export const api_versions = {
  V1: 'v1',
  V1_4: 'v1.4',
  V1_6: 'v1.6',
};

export const cres_partner_keys = {
  PROD: '8046d3c2-ba29-4be4-ab6c-7d82883d7aea',
  TEST: 'b032ade3-dbd3-41ed-8df5-8fb89881d540',
};

export const liquorClasses100: string[] = [
  'c1bb329759ffd595b005384fed6d750a',
  '09b0bc0c0d02048ef7f7cdbd7c6d0b73',
  'a635339f0f5506439c3a4f61d9acd35b',
  '0c61c7267be3667de4242893566f9ee7',
  '6fea06a7c23bfea231533e6b0c4db43a',
  'f3ad7e01f4e1e488a3c199055609b0eb',
  'd9d9920c3b87a275b54e04cf9d8f1bf8',
  'c6a6b57ca7fcc71cc3c4d8103791d172',
  'de7713cdd94630facd43231c2de8235a',
  'fb034027473b1e6ffd1153f4a719c571',
  'ef3f4a2dffaacfe3e0dba0703d4ff3db',
  'b3c2158edc5d9847b8985b578797e72c',
  'c7e162124cf85ee6091b245c179a3a8d',
  '9b030c6f0fb2f8e048975f7e97d254a1',
  'cf56c3e1bf290d545cfd3579b080c278',
  'e002cbb8b1b99421480f651721f5c649',
  '4f527d679fa23a1715309cb100a00373',
  'b7262b04f9fa0dbf8981c3a28ffa66a4',
  '254f352c60ae294ac27facea4aec3613',
  'b840906da4a2337fbd6d8c7170a5b890',
  '6f618033e323c167aa3adc8a55517485',
  '75a46b8fc5eee7a59f6db555ea566f92',
  '9dd4109dd292fb4d1e13d43e50835bb8',
  '7c1d5c7a7bbcfc33acc534fa50649a4e',
];

export const QUOTE_COVERAGE_DEFAULTS = {
  BPP_DEDUCTIBLE: 1_000,
  BPP_LIMIT: 100_000,
  BUILDING_LIMIT: 100_000,
};

export const STATE_ZIP_VERIFICATION_WARNING = {
  text: 'Please double check. We can’t verify this state and zipcode combination.',
  zipErrorText: 'Zip and state do not match',
  stateErrorText: 'State not found for zip code',
};

export const BLANKET_AI_PAYLOAD = {
  [BlanketAIItem.primaryAndNonNonContributory]: { additionalInsuredsPrimaryAndNonContributory: true },
  [BlanketAIItem.waiverOfTransfer]: { additionalInsuredsWaiverOfTransfer: true },
  [BlanketAIItem.constructionContractRequirement]: {
    additionalInsuredsConstructionContractRequirementEndorsement: {
      formText: '<PLACEHOLDER_FOR_CONTRACT_REQUIREMENT_ENDORSEMENT>',
      numAddInsOwnerWithReq: 1,
    },
  },
  [BlanketAIItem.managersOrLessorsPremises]: {
    additionalInsuredsManagersOrLessorsPremisesEndorsement: {
      formText:
        'Any person or organization with whom you agree in a written agreement for lease or rental of premises or building, prior to loss, to name as an additional insured in this Businessowners Coverage.',
      numAddInsManagerPerApartment: 1,
      numAddInsManagerOther: 1,
    },
  },
  [BlanketAIItem.lessorOfLeasedEquipment]: {
    additionalInsuredsLessorOfLeasedEquipmentEndorsement: {
      formText:
        'Any person or organization with whom you agree in a written agreement for lease or rental of equipment, prior to loss, to name as an additional insured in this Businessowners Coverage.',
      numAddInsLeasedEquipPerApartment: 1,
      numAddInsLeasedEquipOther: 1,
    },
  },
};
